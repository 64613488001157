@import 'Styles/Pahlmans/includes';

/**
*
* SearchSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SearchSubjectList.Base.module.scss';

.SearchSubjectList {
    $root: &;

    &__Title {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 14px;
        margin-left: 0;

        @include media(sm) {
            font-size: 2.4rem;
            margin-bottom: 22px;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
    }

    &__Item {
        flex: 1 0 auto;
        position: relative;
        width: calc(50% - 12px);
        min-height: 0 !important;
        margin: 6px;
        border-left: 3px solid $primary-color;
        background: white;

        &::before {
            display: none;
        }

        @include media(sm) {
            width: calc(25% - 12px);
        }
    }

    &__ImageContainer {
        display: none;
    }

    &__Content {
        padding: 27px 20px;
        max-width: none;

        @include media(xs) {
            width: 100%;
        }

        @include media(sm) {
            padding: 27px 30px;
        }
    }

    &__ItemTitle {
        display: block;
        position: relative;
        width: 100%;
        margin: 0;
        padding-right: 30px;
        font-size: 2.4rem;
        text-align: left;

        &:after {
            content: '';
            background-image: $bgArrow;
            position: absolute;
            top: 6px;
            right: 0;
            width: 23px;
            height: 15px;
            background-repeat: no-repeat;
            background-position: right center;
        }

        #{$root}__Item:hover & {
            color: $primary-color;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__Text {
        display: none;
    }
}