@import 'Styles/Medborgarskolan/includes';

/**
*
* SearchSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SearchSubjectList.Base.module.scss';

.SearchSubjectList {
    $root: &;

    &__Title {
        font-size: 3.8rem;
        color: $blue;
        font-weight: $bold;
    }

    &__Item {
        margin-bottom: 18px;

        @include media(xs) {
            margin-bottom: 24px;
        }

        &::before {
            display: none;
        }
    }

    &__ItemContainer {
        border-radius: 8px;
    }

    &__ImageContainer {
        border-radius: 8px 8px 0 0;
        @include media(xs) {
            max-width: 297px;
            border-radius: 8px 0 0 8px;
        }
    }

    &__ItemTitle {
        font-weight: $bold;
        font-size: 2.4rem;
        color: $blue;

        @include media(xs) {
            font-size: 3rem;
        }

        #{$root}--Empty & {
            background: $blue-10;
        }

        #{$root}__Item:hover & {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__Text {
        font-size: 1.4rem;

        @include media(xs) {
            font-size: 1.8rem;
        }
        #{$root}--Empty & {
            background: $blue-10;
        }
    }
}
