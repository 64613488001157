@import 'Styles/Medborgarskolan/includes';

/**
*
* ArticleSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArticleSearch.Base.module.scss';

.ArticleSearch {
    $root: &;

    background: $blue-20;

    &__Container {
        padding-top: 48px;
        padding-bottom: 0;

        @include media(sm) {
            padding-top: 72px;
            padding-bottom: 24px;
        }
    }

    &__Title {
        color: $blue;
        font-size: 3.4rem;
        margin-bottom: 24px;

        @include media(sm) {
            font-size: 4.8rem;
            margin-bottom: 32px;
        }
    }

    &__Form {
        margin-bottom: 48px;
        max-width: 618px;

        @include media(sm) {
            margin-bottom: 64px;
        }
    }

    &__ErrorMessage {
        border-radius: 4px;
    }
}
