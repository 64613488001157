@import 'Styles/Pahlmans/includes';

/**
*
* SubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SubjectList.Base.module.scss';

.SubjectList {
    $root: &;

    margin: 56px 0 32px;

    @include media(sm) {
        margin: 72px 0 40px;
    }

    &--GlobalSearch {
        margin: 0 !important;
    }

    &__Title {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 14px;

        @include media(sm) {
            font-size: 2.4rem;
            margin-bottom: 22px;
        }
    }

    &__Category {
        flex: 1 0 auto;
    }

    &__Link {
        justify-content: flex-start;
        min-height: 0 !important;
        padding: 27px 20px;
        border-left: 3px solid $primary-color;
        background: white;

        @include media(sm) {
            padding: 27px 30px;
        }
    }

    &__ImageContainer {
        display: none !important;
    }

    &__LinkTitle {
        display: block;
        position: relative;
        width: 100%;
        padding-right: 30px;
        font-size: 2.4rem;
        text-align: left;

        &:after {
            content: '';
            background-image: $bgArrow;
            position: absolute;
            top: 6px;
            right: 0;
            width: 23px;
            height: 15px;
            background-repeat: no-repeat;
            background-position: right center;
        }

        #{$root}__Link:hover & {
            color: $primary-color;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__Button {
        display: none;
    }
}
