@import 'Styles/Kulturama/includes';

/**
*
* SubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SubjectList.Base.module.scss';

.SubjectList {
    $root: &;

    &__Container {
    }

    &__Wrapper {
    }

    &__Category {
        height: calc(50% - 12px);

        @include media(sm) {
            width: calc(50% - 12px);
        }

        @include media(md) {
            width: calc(25% - 12px);
        }

        &--HiddenXS {
            position: absolute;
            overflow: hidden;
            width: 0;
            height: 0;
            margin: 0;
            opacity: 0;
            z-index: -1;
            transition: none;

            @include media(sm) {
                position: relative;
                overflow: visible;
                width: calc(25% - 12px);
                height: auto;
                margin: 6px;
                opacity: 1;
                z-index: 1;
            }
        }

        &--HiddenSM {
            @include media(sm) {
                position: absolute;
                overflow: hidden;
                width: 0;
                height: 0;
                margin: 0;
                opacity: 0;
                z-index: z(behind);
            }
        }
    }

    &__Link {
        @include media(sm) {
            min-height: 281px;
            padding: 0 40px;
        }

        @include media(md) {
            min-height: 281px;
        }
    }

    &__ImageContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        opacity: 1;
        transition: opacity 0.5s ease-out;
        z-index: z(behind);

        &:hover {
            &::after {
                opacity: 0.3;

                @media(hover: none) {
                    opacity: 0;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(black, .3);
            background: linear-gradient(to bottom, rgba(black, .2) 40%,rgba(black, .6) 100%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            box-shadow: -3px 3px 10px black;
            opacity: 0;
            transition: opacity .2s ease-in;
        }
    }

    &__LinkTitle {
        position: relative;
        color: inherit;
        background-image: linear-gradient(120deg, #fff 0%, #fff 100%);
        background-repeat: no-repeat;
        background-size: 100% 2px;
        background-position: 0 100%;
        transition: background-size 0.25s ease-out;

        &:before{
            content: attr(data-title);
            display: block;
            position: absolute;
            color: black;
            opacity: 0;
            transition: opacity .25s ease-out;
        }

        #{$root}__Category:hover & {
            background-size: 100% 88%;
        }

        #{$root}__Category:hover &:before {
            opacity: 1;
        }

        @include media(md) {
            font-size: 2.6rem;
        }
    }
}
