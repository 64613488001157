@import 'Styles/Base/includes';

/**
*
* ArticleSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArticleSearch {
    $root: &;

    &__Container {
        @include u-wrap(article);

        padding-top: 10px;
        padding-bottom: 60px;

        @include media(sm) {
            padding-top: 20px;
        }

        @include media(md) {
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }

    &__Error {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    &__ErrorMessage {
        position: relative;
        padding: 14px 20px;
        color: $error-color;
        font-size: 1.4rem;
        text-align: center;
        border: 1px solid $error-color;
        background: rgba($error-color, .04);
    }
}
