@import 'Styles/Base/includes';

/**
*
* SearchSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SearchSubjectList {
    $root: &;

    &__Container {
        @include clearfix; // To wrap margin in NoResult

        @include media(xs) {
            @include u-wrap(article);
        }

        #{$root}--GlobalSearch & {
            padding: 0 !important;
        }
    }

    &__Title {
        margin-left: 12px;
        margin-bottom: 20px;
        font-size: 1.8rem;
        line-height: 1.3;

        @include media(xs) {
            margin-left: 0;
        }

        @include media(sm) {
            margin-bottom: 40px;
        }
    }

    &__Item {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 148px;
        margin-bottom: 5px;
        cursor: pointer;

        @include media(sm) {
            margin-bottom: 8px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px 6px 9px black;
            box-shadow: -10px 15px 25px 0 black;
            opacity: 0;
            transition: opacity .2s ease-in;
        }

        &--Hover:hover::before {
            opacity: .3;

            @media(hover: none) {
                opacity: 0;
            }
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z(SearchItem);
    }

    &__ItemContainer {
        flex: 1 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;

        @include media(xs) {
            flex-direction: row;
        }
    }

    &__ImageContainer {
        position: relative;
        flex: 0 1 auto;
        max-height: 130px;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 50%;
        }

        @include media(xs) {
            width: 30%;
            max-height: none;
            max-width: 328px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Content {
        position: relative;
        max-width: 628px;
        padding: 25px 20px 16px;

        @include media(xs) {
            width: 70%;
        }

        @include media(sm) {
            padding: 35px 45px;
        }
    }

    &__ItemTitle {
        margin-bottom: 10px;
        font-size: 1.8rem;
        line-height: 1.35;

        #{$root}--Hover:hover & {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        #{$root}--Empty & {
            position: relative;
            max-width: 300px;
            height: 24px;
            padding-bottom: 0;
            margin-bottom: 18px;
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;

        #{$root}--Empty & {
            position: relative;
            max-width: 500px;
            height: 21px;
            padding-bottom: 0;
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }
    }
}
