@import 'Styles/Pahlmans/includes';

/**
*
* ArticleSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArticleSearch.Base.module.scss';

.ArticleSearch {
    $root: &;

    &__Form {
        margin-top: 24px;
    }
}
