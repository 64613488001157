@import 'Styles/Base/includes';

/**
*
* SubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SubjectList {
    $root: &;

    &__Container {
        @include u-wrap(site);

        padding-top: 45px;
        padding-bottom: 45px;
        text-align: center;

        #{$root}--Small & {
            padding-top: 45px;
        }

        @include media(sm) {
            padding-top: 60px;
            padding-bottom: 70px;
        }

        @include media(md) {
            padding-top: 80px;
            padding-bottom: 110px;
        }

        #{$root}--GlobalSearch & {
            padding: 0 !important;
        }
    }

    // &__Title {
    //     margin-bottom: 20px;
    //     font-weight: $bold;

    //     #{$root}--Small & {
    //         margin-bottom: 10px;
    //         font-size: 2rem;
    //         text-align: left;
    //     }

    //     @include media(sm) {
    //         margin-bottom: 30px;
    //     }
    // }

    &__Categories {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
    }

    &__Wrapper {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: black;
            box-shadow: -5px 5px 8px black;
            opacity: 0;
            z-index: z(behind);
            transition: opacity .2s ease-in;
        }
    }


    &__Category {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(50% - 12px);
        height: auto;
        margin: 6px;

        @include media(sm) {
            width: calc(25% - 12px);
        }
    }

    &__CategoryWrapper {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }

    &__Link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 20px;
        min-height: 150px;
        height: 100%;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 1.3;
        font-weight: $bold;
        z-index: z();

        @include media(md) {
            min-height: 160px;
            padding: 0 40px;
        }
    }

    &__ImageContainer {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Icon {
        display: none;
    }

    &__Button {
        position: relative;
        margin: 30px auto 0;
        z-index: 100;

        #{$root}--All & {
            display: none;
        }
    }
}
