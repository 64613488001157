@import 'Styles/Medborgarskolan/includes';

/**
*
* SubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SubjectList.Base.module.scss';

.SubjectList {
    $root: &;

    background: $blue-20;

    &__Container {
        padding-top: 40px;
        padding-bottom: 55px;
        text-align: left;

        #{$root}--Small & {
            padding-top: 40px;
        }

        @include media(md) {
            padding-top: 70px;
            padding-bottom: 80px;
        }

        #{$root}--NoTopSpacing & {
            padding-top: 0 !important;
        }
    }

    &__Title {
        margin-bottom: 20px;
        font-size: 3.4rem;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            margin-bottom: 30px;
            font-size: 4.8rem;
        }

        #{$root}--Small & {
            margin-bottom: 20px;
            font-size: 2.4rem;
            text-align: left;

            @include media(sm) {
                margin-bottom: 24px;
                font-size: 2.6rem;
            }
        }
    }

    &__Wrapper {
    }

    &__Categories {
        margin: 0 0 -20px -20px;

        @include media(md) {
            margin: 0 0 -24px -24px;
        }
    }

    &__Category {
        margin: 0 0 20px 20px;
        min-height: 80px;
        width: calc(100% - 20px);
        border-radius: 8px;
        background-color: white;

        @include media(xs) {
            width: calc(50% - 20px);
        }

        @include media(md) {
            min-height: 96px;
            width: calc(#{percentage(1/3)} - 24px);
            margin: 0 0 24px 24px;
        }

        &--HiddenXS {
            position: absolute;
            overflow: hidden;
            width: 0;
            height: 0;
            margin: 0;
            opacity: 0;
            z-index: -1;
            transition: none;

            @include media(sm) {
                position: relative;
                overflow: visible;
                width: calc(25% - 12px);
                height: auto;
                margin: 6px;
                opacity: 1;
                z-index: 1;
            }
        }

        &--HiddenSM {
            @include media(sm) {
                position: absolute;
                overflow: hidden;
                width: 0;
                height: 0;
                margin: 0;
                opacity: 0;
                z-index: z(behind);
            }
        }
    }

    &__Link {
        padding: 12px;
        justify-content: flex-start;
        height: 100%;
        min-height: fit-content;
        font-size: 1.8rem;
        line-height: 1.3;
        font-weight: $bold;
        text-align: left;
        border-radius: 8px;
        z-index: 0;

        @include media(sm) {
            padding: 16px;
            font-size: 2.3rem;
        }

        #{$root}__Category:hover & {
            text-decoration: none;

            @media (hover: none) {
                text-decoration: none;
            }
        }
    }

    &__ImageContainer {
        flex: 0 0 auto;
        position: relative;
        width: 56px;
        height: 56px;
        margin-right: 16px;
        border-radius: 50%;
        background-color: $coral-20;
        overflow: hidden;

        @include media(md) {
            width: 64px;
            height: 64px;
            margin-right: 24px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $coral-20 url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow-handdrawn--blue.svg') no-repeat -40px center;
            background-size: 24px;
            transition: background-position $transition;
            backdrop-filter: blur(100px) brightness(1); // Hack to remove small outline shown from Image background

            @include media(md) {
                background-position: -44px center;
            }
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: inherit;
            transform: translateX(0);
            transition: transform $transition, opacity $transition;
            backdrop-filter: blur(100px) brightness(1); // Hack to remove small outline shown from Image background

            #{$root}__Category--IsIcon & {
                display: none;
            }
        }

        #{$root}__Link:hover & {
            &::before {
                background-position: 16px center;

                @include media(md) {
                    background-position: 20px center;
                }
            }

            &::after {
                opacity: 0;
            }
        }
    }

    &__Image {
        border-radius: 100%;
        transform: translateX(0);
        transition: transform $transition, opacity $transition;
        overflow: hidden;
        z-index: 1;

        #{$root}__Link:hover & {
            opacity: 0;
        }
    }

    &__Icon {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 32px;
        height: 32px;
        margin: auto;
        z-index: 1;
        transform: translateX(0);
        transition: transform $transition, opacity $transition;

        @include media(sm) {
            width: 44px;
            height: 44px;
        }

        #{$root}__Link:hover & {
            opacity: 0;
            transform: translateX(56px);

            @include media(md) {
                transform: translateX(64px);
            }
        }
    }

    &__LinkTitle {
        font-size: 2.4rem;
        color: $blue;
        transition: transform $transition;

        #{$root}__Link:hover & {
            transform: translateX(6px);
        }

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__Button {
        @include u-button(secondary);
    }
}
